import '@splidejs/splide/css'
import Splide from '@splidejs/splide'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/all'

import { wfSliderArrows, wfTabs, addSplideClasses, sel, selAll, addStaticSplideClasses, selector, mm, splideCarousel, stLineWrap } from './utils'
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll'
import SplitType from 'split-type'

gsap.registerPlugin(ScrollTrigger)

export default function Lp() {
  console.log('lp')

  const navbar$ = sel('.navbar')
  const hero$ = sel('.lp-hero')
  const heroH$ = sel('.lp-hero__info__h')
  const heroP$ = sel('.lp-hero__info__p')
  const heroBtnW$ = sel('.lp-hero__btn-w')
  const heroBtn$a = selAll('.lp-hero__btn-w .button')
  const heroBg$ = sel('.lp-hero__bg-w')

  const heroIcoDis$ = sel('.lp-hero__icons__img.is--dis')
  const heroIcoX$ = sel('.lp-hero__icons__img.is--x')
  const heroIcoTel$ = sel('.lp-hero__icons__img.is--tel')
  const heroIcoFb$ = sel('.lp-hero__icons__img.is--fb')
  const heroIcoIg$ = sel('.lp-hero__icons__img.is--ig')

  const heroHsT = new SplitType(heroH$, { types: 'words, chars' })
  const heroPsT = new SplitType(heroP$, { types: 'lines' })
  stLineWrap(heroPsT.lines)

  const heroTl = gsap.timeline({ defaults: { ease: 'power4.out', duration: 1 } })
  heroTl
    .from(
      heroHsT.chars,
      {
        opacity: 0,
        filter: 'blur(50px)',
        stagger: {
          amount: 0.5,
        },
        duration: 0.75,
      },
      0.5
    )
    .from(heroPsT.lines, { yPercent: 110, stagger: { amount: 0.2 } }, 1)
    .from(heroBtnW$, { opacity: 0, stagger: { amount: 0.5 }, duration: 5 }, 1.25)
    .from([heroIcoDis$, heroIcoX$, heroIcoTel$, heroIcoFb$, heroIcoIg$], { y: 20, opacity: 0, stagger: { amount: 0.5 }, duration: 2.5 }, 1.5)
    .from(navbar$, { opacity: 0, duration: 3 }, 2)

  // gsap.to(heroBg$, {
  //   scrollTrigger: {
  //     trigger: hero$,
  //     start: 'top top',
  //     end: 'bottom top',
  //     scrub: 0,
  //   },
  //   // scale: 1.1,
  //   yPercent: 20,
  // })
  const benSec$ = sel('.benefits-sec')

  ScrollTrigger.create({
    trigger: benSec$,
    start: 'top bottom=+1',
    end: 'top top',
    pin: hero$,
    pinSpacing: false,
    scrub: 0,
    // animation: gsap.to(heroBg$, { scale: 1.1 }),
  })

  const propItem$a = selAll('.benefits__item')
  gsap.from(propItem$a, {
    scrollTrigger: {
      trigger: '.benefits__item-w',
      start: 'top 80%',
    },
    y: 50,
    opacity: 0,
    ease: 'power4.out',
    stagger: {
      amount: 0.4,
    },
    duration: 1.2,
  })
}
