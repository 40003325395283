import './style.styl'
import { sel, selAll, wfTabs, mm } from './utils'
import Home from './home'
import Lp from './lp'
/* 
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import Lenis from '@studio-freight/lenis'

const lenis = new Lenis()
lenis.on('scroll', ScrollTrigger.update)
gsap.ticker.add((time) => {
  lenis.raf(time * 1000)
})
gsap.ticker.lagSmoothing(0) */

const _dataPage = sel('.page-wrapper') || sel('body')
const dataPage = _dataPage?.getAttribute('data-page')
switch (dataPage) {
  case 'home':
    Home()
    break
  case 'lp':
    Lp()
    break

  case 'error':
    Error()
    break
  default:
    console.log('unknown data-page:', dataPage)
}

wfTabs('tabs-mm', 'mouseenter')
const navbarTabs$a = selAll('.navbar__mm__tab')

function clickTabHandle(tab, pane) {
  const button$ = sel(`#${pane} .button`)
  button$.click()
}
navbarTabs$a.forEach((tab) => {
  // tab.addEventListener('mouseenter', function () {
  //   changeTabHandle(this)
  // })
  tab.addEventListener('click', function (e) {
    // e.preventDefault()
    mm.add('(max-width: 991px)', () => {
      e.stopPropagation()
    })

    const pane = tab.dataset.paneId

    clickTabHandle(this, pane)
  })
})
